$color-transparent: 'transparent';
$color-primary: #C02EB7;
$color-secondary: #4440EE;
$color-tertiary: #59B5F7;
$color-grey1: #2B2A30;
$color-grey2: #4C4C56;
$color-grey3: #ACB2BF;
$color-placeholder: #ACB2BF;

.App {
  overflow-x: hidden;

  @media(min-width: 420px) {
    position: absolute;
    width: 100%;
  }

  @media(min-width: 1440px) {

  }
}

.margin {

  overflow: hidden;
  @media (min-width: 0px) {
    margin: 25px;
  }

  @media (min-width: 376px) {
    margin: 50px;
  }

  @media (min-width: 768px) {
    margin: 85px;
  }
}

.margin-bottom {
  margin-bottom: 86px;
  @media (min-width: 768px) {
    margin-bottom: 136px !important;
  }
}

.padding {
  @media (min-width: 0px) {
    padding: 25px;
  }

  @media (min-width: 376px) {
    padding: 50px;
  }
}

.stage-overlap {

  margin: 25px;

  @media (min-width: 1440px) {
    margin: auto;
    margin-top: -250px;
    padding-left: 109px;
    padding-right: 109px;
    padding-top: 90px;
    position: relative;
    background-color: white;
    margin-bottom: 0px;
    max-width: 1284px;
    width: calc(100% - 320px);
    min-height: 212px;
  }
}

.overlap-margin {

  padding: 24px;

  @media (min-width: 768px) {
    padding: 48px;
  }

  @media (min-width: 1440px) {
    margin: auto;
    max-width: 1284px;
  }
}

.center {
  justify-content: center;
  display: flex;
}

.background-transparent {
  background-color: $color-transparent;
}

.background-primary {
  background-color: $color-primary;
}

.background-secondary {
  background-color: $color-secondary;
}

.background-tertiary {
  background-color: $color-tertiary;
}

.background-grey1 {
  background-color: $color-grey1;
}

.background-grey2 {
  background-color: $color-grey2;
}

.background-grey3 {
  background-color: $color-grey3;
}

.legal-page {

  margin-bottom: 200px;

  h1 {
    font-family: Futura PT Demi, Trebuchet MS;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 26px;
    letter-spacing: 3px;
  }

  h2, h3 {
    font-family: Futura PT Demi, Trebuchet MS;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 3px;
  }

  li {
    font-family: Futura PT Book,Trebuchet MS;
    font-size: 18px;
    letter-spacing: 1px;
  }

  p {
    font-family: Futura PT Book, Trebuchet MS;
    font-style: normal;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 1px;
  }

  .siegel {
    margin-right: 24px;
  }

  a {
    font-size: 18px !important;
    line-height: 28px !important;
    margin-left: 0px !important;
  }
}

/* Obfuscated links for email and phone */
.email, .phone {
  display: flex;
  font-family: "Futura PT Book", "Trebuchet MS";
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 24px;
  color: $color-grey1;
  text-align: left;
  margin: 0px;
  margin-left: 6px !important;

  &:hover {
    color: $color-primary !important;
  }

  @media (min-width:768px) {
    font-size: 18px;
    line-height: 28px;
    max-width: 90%;
  }

  @media (min-width:1440px) {
    font-size: 24px;
    line-height: 34px;
  }
}

.inline-link {
  display: contents;
}

/* Markup content */
.markup-content {
  font-family: "Futura PT Book", "Trebuchet MS";
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 24px;
  color: $color-grey1;
  text-align: left;
  margin: 0px;
  list-style-image: url(../assets//icons/list-item.svg);
  list-style-position: inside;
  display: inline-block;

  @media(min-width: 420px) {
    font-size: 18px;
    line-height: 28px;
  }

    @media(min-width: 1440px) {
    font-size: 24px;
    line-height: 34px;
  }
}
