body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

@font-face {
  font-family: 'Futura PT Heavy';
  src: url('./../assets/fonts/FuturaPT-Heavy.woff2') format('woff2'),
      url('./../assets/fonts/FuturaPT-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT Book';
  src: url('./../assets/fonts/FuturaPT-Book.woff2') format('woff2'),
      url('./../assets/fonts/FuturaPT-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT Demi';
  src: url('./../assets/fonts/FuturaPT-Demi.woff2') format('woff2'),
      url('./../assets/fonts/FuturaPT-Demi.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

html {
  scroll-behavior: smooth;
}
