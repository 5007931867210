/* Toggle Buttons*/
.slider.active {
    background-color: #D8D8D8 !important;
    box-shadow: unset !important;
}

/* Buttons */
.cm-btn {
    padding: 8px !important;
    font-family: 'Futura PT Heavy' !important;
    background-color: transparent !important;
    text-decoration: underline;
    margin-right: 0px !important;

}

/* Links */
.cm-link {
    color: white !important;
}

/* Modal */
.klaro .cookie-modal .cm-modal.cm-klaro {
    background-color: #C02EB7 !important;
    border-radius: 8px !important;
    font-family: "Futura PT Book", "Trebuchet MS" !important;
    //filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
    font-size: 18px !important;
    line-height: 28px;
    border-radius: 0px !important;
    box-shadow: none !important;
}

.klaro h1 {
  font-family: "Futura PT Heavy", "Trebuchet MS" !important;
}

.cm-list-description {
  color: white !important;
}

.purposes {
  color: white !important;
}

.cm-list-title {
  font-family: "Futura PT Heavy", "Trebuchet MS" !important;
}

/* Banner */
.klaro .cookie-notice:not(.cookie-modal-notice) {
    background-color: #C02EB7 !important;
    font-family: "Futura PT Book", "Trebuchet MS" !important;
    font-size: 16px !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    line-height: 28px;
}

/* Texts */
strong {
    color: black !important;
}

/* Modal Texts */
.klaro .cookie-modal a, .klaro .context-notice a, .klaro .cookie-notice a {
    color: white !important;
    text-decoration: underline !important;
}

.cm-list-title {
    font-size: 16px !important;
}

#klaro-cookie-content h1 span {
    font-size: 24px !important;
}

/* Powered by */
.cm-powered-by {
    display: none !important;
}

/* Footer */
.cm-footer {
  border-top-color: white !important;
}

/* Header */
.cm-header {
  border-bottom-color: white !important;
}
